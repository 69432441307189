import copyright from "../assets/copyright.png"

const Copyright = () => (
    <div className="copyright">
        <h1>
            Copyrights
            <span><img src={copyright} alt="copyright" width="30" /></span>
            All rights reserved by Green Enviro Soloutions Ltd</h1>
    </div>
)

export default Copyright
