import React from 'react'
import PropTypes from 'prop-types'

const Heading = ({id, text}) => {
    return (
        <div className="heading">
        <hr />
        <h1 id={id}>{text}</h1>
        <hr />
    </div>
    )
}

Heading.propTypes = {
id: PropTypes.string.isRequired,
text: PropTypes.string.isRequired,
}

export default Heading;