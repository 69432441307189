import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

const ModalContainer = ({ onCloseHandler, isOpen, description, heading }) => (
  <Modal
    center
    classNames={{
      overlay: "customOverlay",
      modal: "customModal",
    }}
    open={isOpen}
    onClose={onCloseHandler}
  >
    <h2 className="modal-heading">{heading}</h2>
    <hr />
    <p className="modal-description">{description}</p>
  </Modal>
);

ModalContainer.propTypes = {
  onCloseHandler: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
};

export default ModalContainer;
