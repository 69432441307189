import { useState } from "react";
import Heading from "../components/Heading";
import ModalContainer from "../components/ModalContainer";
import { data as getData } from "../lib/helper";

const About = () => {
  const data = getData();
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  const renderAboutContent = (image, heading, i) => (
    <div className="about-container col-md-4">
      <h1>{heading}</h1>
      <img src={image} alt="" />
      <p>{data[i].info}...</p>
      <button
        onClick={() => {
          setIndex(i);
          setIsOpen(true);
        }}
      >
        Learn more
      </button>
    </div>
  );

  return (
    <>
      <Heading id="about" text="About Us" />
      <div className="about">
        <div className="about-wrapper">
          <div className="row">
            {renderAboutContent(data[0].img, data[0].label, 0)}
            {renderAboutContent(data[1].img, data[1].label, 1)}
            {renderAboutContent(data[2].img, data[2].label, 2)}
          </div>
          {/* {renderAboutContent(data[2].img, data[2].label, data[2].info)} */}
          {/* {renderAboutContent(data[1].img, data[1].label, data[1].info)} */}
        </div>
      </div>
      <ModalContainer
        isOpen={isOpen}
        onCloseHandler={() => setIsOpen(false)}
        description={data[index].info}
        heading={data[index].label}
      />
    </>
  );
};

export default About;
