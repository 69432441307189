import { useEffect, useState } from "react";
import bgImg from "../assets/bgImg.jpg";
import down from "../assets/down.gif";

const Homepage = () => (
  <div id="/" className="homepage">
    <img src={bgImg} alt="bgImg" />
    <div className="homepage-overlay">
      <div id="abc" className="outer">
        <h2 className="welcome">Welcome to</h2>
        <h1 className="company_name">Green Enviro Soloutions Ltd</h1>
        <h2 className="info">Plastic Recycling & Regrind Specialist</h2>
      </div>
    </div>

    <a href="#about">
      <img src={down} alt="down" />
    </a>
  </div>
);

export default Homepage;
