import { useState } from "react";
import emailjs from "emailjs-com";
import toast from "react-hot-toast";
import Heading from "../components/Heading";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeHandler = (setState) => (e) => setState(e.target.value);

  const _submit = async (e) => {
    e.preventDefault();

    const user_id = "MqVpjgPr7YWb_69M3";
    const service_id = "service_tjnjh4h";
    const template_id = "template_9t2sglg";

    const emailData = {
      name: name,
      email: email,
      message: message,
    };

    setLoading(true);
    await emailjs.send(service_id, template_id, emailData, user_id).then(
      (result) => {
        console.log(result.text);
        toast.success("Message send to Green Enviro Soloutions Ltd team");
      },
      (error) => {
        console.log(error.text);
        toast.error("Message not send successfully");
      }
    );

    setName("");
    setEmail("");
    setMessage("");
    e.target.reset();
    setLoading(false);
  };

  const renderContactForm = () => (
    <form onSubmit={_submit} className="contact-form">
      <div className="input-field">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          placeholder="Enter name"
          value={name}
          name="name"
          className="form-control"
          required
          onChange={onChangeHandler(setName)}
        />
      </div>
      <div className="input-field">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          placeholder="Enter email"
          value={email}
          name="email"
          className="form-control"
          required
          onChange={onChangeHandler(setEmail)}
        />
      </div>
      <div className="input-field">
        <label htmlFor="message">Message</label>
        <textarea
          name=""
          id=""
          cols="10"
          rows="5"
          className="form-control"
          required
          onChange={onChangeHandler(setMessage)}
        />
      </div>
      <button
        type="submit"
        className={`send-btn ${loading ? "submit_loader" : ""}`}
        disabled={loading}
      >
        {loading ? "" : "Send Message"}
      </button>
    </form>
  );

  return (
    <>
      <Heading id="contact" text="Contact Us" />
      <div className="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-6">{renderContactForm()}</div>
            <div className="col-md-6">
              <h1>Address</h1>
              <p>
              20-23 Woodside Place, Glasgow, Scotland, G3 7QL
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
