import img_1 from "../assets/img1.jpg";
import img_2 from "../assets/img2.jpg";
import img_3 from "../assets/img3.jpg";
import img_4 from "../assets/img4.jpg";

export const data = () => [
  {
    img: img_1,
    label: "Waste Audits",
    info: `Our basic waste management plan is usually made up of an audit, a waste diversion and a waste reduction strategy
    We are look up what can be recycled, and what can be thrown in the trash. Green Enviro Soloutions team can 
    conduct the waste audit on their own, or you can enlist the help of your company’s cleaning staff. 
    They will be instrumental in helping you pull off a successful audit!`,
  },
  {
    img: img_2,
    label: "Waste collection",
    info: `Green Enviro Soloutions provide Waste Plastic Collection Service a reliable, regular collection of unused 
    plastic waste. We collect mixed loads of plastic waste for companies unable to sort waste on-site and collect from 
    companies with pre-sorted plastics waste. From manufacturing and construction to distribution and agriculture, 
    Green Enviro Soloutions provide a flexible service for most industry sectors. Our baler system can help to 
    reduce the volume of plastic waste in storage, while it awaits collection`,
  },
  {
    img: img_3,
    label: "Recycled and Regrind",
    info: `Green Enviro Soloutions recycles a large volume of different plastic types, including HDPE, LDPE, PVC and PETE. We collect waste plastics from 
    various industries, which are sorted by resin and quality at our network of MRF’s (materials recovery facilities). Once waste plastics 
    are sorted we use a process to shred or grind the plastics, sometimes known as regrind, which can be used for manufacturing as is. 
    The plastic regrind goes through washing process to remove impurities and is then extruded to produce consistent pellets, ready for use.`,
  },
];
