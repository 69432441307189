import React from "react";
import Copyright from "../components/Copyright";
import Separator from "../components/Separator";
import TopNav from "../components/TopNav";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Homepage from "../pages/Homepage";

const HomePageLayout = () => (
  <>
    <TopNav />
    <Homepage />
    <Separator color="green" />
    <About />
    <Separator color="black" />
    <Contact />
    <Copyright />
  </>
);

export default HomePageLayout;
